import React from 'react'
import Layout from "../../components/layout";
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

function Diensten() {

  const sitename = "Bart Sallé, freelance webdeveloper";
  const pagetitle = "Diensten - Bart Sallé, freelance webdeveloper";
  const description = "Websites, webwinkels, maatwerk, styling, SEO, design & content creation, hosting & onderhoud. Een uitgebreid pakket aan diensten.";
  const url = "https://www.bartsalle.nl/diensten/";

  return (
    <Layout>
      <Helmet>
        <title>{pagetitle}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pagetitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={sitename} />
        <body className="overzichtdiensten" />
      </Helmet>
        <div className="container">
          <h1>Diensten</h1>
          <h2>Webdesign</h2>
          <p><Link to="/diensten/webdesign/">Lees meer...</Link></p>
          <h2>Webdevelopment</h2>
          <p><Link to="/diensten/webdevelopment/">Lees meer...</Link></p>
          <h2>Hoger in Google (SEO/zoekmachine optimalisatie)</h2>
          <p><Link to="/diensten/hoger-in-google/">Lees meer...</Link></p>
          <h2>Design & Content creation</h2>
          <p><Link to="/diensten/design-en-content-creation/">Lees meer...</Link></p>
          <h2>Hosting & Onderhoud</h2>
          <p><Link to="/diensten/hosting-en-onderhoud/">Lees meer...</Link></p>
        </div>
    </Layout>
  )
}

export default Diensten